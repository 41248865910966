import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {HomeOutlined, MessageOutlined, AlertOutlined, DatabaseOutlined, LoginOutlined, BellOutlined, AppstoreOutlined} from "@ant-design/icons";
//회원 전용 루트
import { Navigate } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: "/",
    component: () => {
      if (localStorage.getItem("token")) {
        return <Navigate to="/company" />
      } else {
        return <Navigate to="/login" />
      }
    },
  },
  {
    exact: true,
    path: "/login",
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/dashboard",
    sidebar: {
      icon: <AppstoreOutlined />,
      label: "대시보드",
    },
    component: loadable(() => import("../pages/dashboard/List")),
  },
  {
    exact: true,
    path: "/company",
    sidebar: {
      icon: <HomeOutlined />,
      label: "사업장 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "사업장 리스트",
        },
        component: loadable(() => import("../pages/company/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/company/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/company/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/overall",
    sidebar: {
      icon: <MessageOutlined />,
      label: "오버롤 리뷰 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "사업장 리스트",
        },
        component: loadable(() => import("../pages/overall/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/review/:id",
        component: loadable(() => import("../pages/overall/Review")),
      },
      {
        exact: true,
        visible: false,
        path: "/review/register/:id",
        component: loadable(() => import("../pages/overall/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/review/detail/:id",
        component: loadable(() => import("../pages/overall/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/sensor",
    sidebar: {
      icon: <AlertOutlined />,
      label: "센서 관리",
    },
    children: [
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "센서 카테고리 리스트",
        },
        component: loadable(() => import("../pages/sensor/category/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/detail/:id",
        component: loadable(() => import("../pages/sensor/category/Modify")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "센서 리스트",
        },
        component: loadable(() => import("../pages/sensor/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/sensor/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/sensor/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/data",
    sidebar: {
      icon: <DatabaseOutlined />,
      label: "데이터 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "데이터 리스트",
        },
        component: loadable(() => import("../pages/data/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/data/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/data/Modify")),
      }
    ],
  },
  {
    exact: true,
    path: "/alarm",
    sidebar: {
      icon: <BellOutlined />,
      label: "알림 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "알림 발송내역",
        },
        component: loadable(() => import("../pages/alarm/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/alarm/Register")),
      }
    ],
  },
  {
    exact: true,
    path: "/image",
    sidebar: {
      icon: <LoginOutlined />,
      label: "로그인 페이지 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "이미지 리스트",
        },
        component: loadable(() => import("../pages/login/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/login/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/login/Modify")),
      }
    ],
  },
];
