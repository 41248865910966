import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Layout, Menu, Popconfirm, Popover, Badge, Avatar } from "antd";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";

import { privateRoutes } from "../routes/routes";
import constant from "../data/constant";
import { useLogout } from "../recoil/auth";
import useSWR from "swr";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const ServiceWrapper = ({ children }) => {
    const history = useNavigate();

    const logout = useLogout();
    const location = useLocation();
    //const [gradeInfo, setGradeInfo] = useState([]);
    const [privateRoute, setPrivateRoute] = useState(privateRoutes);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isVisibleProfilePopover, setIsVisibleProfilePopover] = useState(false);
    const adminGradeId = localStorage.getItem('admin_grade_id');

    const pathName = location.pathname;
    const rootPath = pathName.substring(0, pathName.indexOf("/", 2));
    const pathArray = pathName.split("/")

    const { data, mutate } = useSWR(`/admin/grade/detail?admin_grade_id=${adminGradeId}`);
    const originalData = data?.data?.admin_grade;

    useEffect(() => {
        if (privateRoute && originalData) {
            if (originalData?.dashboard_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/dashboard");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.user_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/user");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.product_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/product");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.detailing_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/detailing");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.order_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/order");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.stats_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/stats");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.survey_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/preference");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.contents_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/contents");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.coupon_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/coupon");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.cash_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/cash");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.alarm_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/alarm");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.admin_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/admin");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
            if (originalData?.etc_manage !== 1) {
                const fondRoute = privateRoute.find(route => route.path === "/etc");
                if (fondRoute) {
                    const index = privateRoute.indexOf(fondRoute);
                    let newPrivateRoute = [...privateRoute];
                    newPrivateRoute.splice(index, 1);
                    setPrivateRoute(newPrivateRoute);
                }
            }
        }
    }, [privateRoute, originalData]);

    useEffect(() => {
        if (originalData) {
            const gradeInfo = [];
            for (const key in originalData) {
                if (originalData.hasOwnProperty(key) && originalData[key] === 1) {
                    gradeInfo.push(key);
                }
            }

            if (originalData?.dashboard_manage !== 1 && pathName.includes('dashboard')) {
                redirectBasedOnGrade(gradeInfo)
            } else if (originalData?.user_manage !== 1 && pathName.includes('user')) {
                redirectBasedOnGrade(gradeInfo)
            }else if (originalData?.product_manage !== 1 && pathName.includes('product')) {
                redirectBasedOnGrade(gradeInfo)
            }else if (originalData?.detailing_manage !== 1 && pathName.includes('detailing')) {
                redirectBasedOnGrade(gradeInfo)
            }else if (originalData?.order_manage !== 1 && pathName.includes('order')) {
                redirectBasedOnGrade(gradeInfo)
            }else if (originalData?.stats_manage !== 1 && pathName.includes('stats')) {
                redirectBasedOnGrade(gradeInfo)
            }else if (originalData?.survey_manage !== 1 && pathName.includes('preference')) {
                redirectBasedOnGrade(gradeInfo)
            }else if (originalData?.contents_manage !== 1 && pathName.includes('contents')) {
                redirectBasedOnGrade(gradeInfo)
            }else if (originalData?.coupon_manage !== 1 && pathName.includes('coupon')) {
                redirectBasedOnGrade(gradeInfo)
            }else if (originalData?.cash_manage !== 1 && pathName.includes('cash')) {
                redirectBasedOnGrade(gradeInfo)
            }else if (originalData?.alarm_manage !== 1 && pathName.includes('alarm')) {
                redirectBasedOnGrade(gradeInfo)
            }else if (originalData?.admin_manage !== 1 && pathName.includes('admin')) {
                redirectBasedOnGrade(gradeInfo)
            }else if (originalData?.etc_manage !== 1 && pathName.includes('etc')) {
                redirectBasedOnGrade(gradeInfo)
            }
        }
    }, [originalData]);

    const redirectBasedOnGrade = (gradeInfo) => {
        if (gradeInfo.length > 0) {
            gradeInfo?.some((data, key) => {
                if (data === "dashboard_manage") {
                    history.push('/dashboard/count')
                    return true;
                } else if (data === 'user_manage') {
                    history.push('/user/index')
                    return true;
                } else if (data === "product_manage") {
                    history.push('/product/index')
                    return true;
                } else if (data === "detailing_manage") {
                    history.push('/detailing')
                    return true;
                } else if (data === "order_manage") {
                    history.push('/order')
                    return true;
                } else if (data === "stats_manage") {
                    history.push('/stats/sales')
                    return true;
                } else if (data === "survey_manage") {
                    history.push('/preference/questionnaire')
                    return true;
                } else if (data === "contents_manage") {
                    history.push('/contents/dentRepair')
                    return true;
                } else if (data === "coupon_manage") {
                    history.push('/coupon')
                    return true;
                } else if (data === "cash_manage") {
                    history.push('/cash')
                    return true;
                } else if (data === "alarm_manage") {
                    history.push('/alarm')
                    return true;
                } else if (data === "admin_manage") {
                    history.push('/admin/grade')
                    return true;
                } else if (data === "etc_manage") {
                    history.push('/etc/term')
                    return true;
                } else {
                    history.push('/login')
                    return false;
                }
            })
        }

    };

    const openKey = [];
    openKey[0] = pathArray.length > 2 ? rootPath : pathName;
    if (pathArray.length > 3) {
        openKey[1] = '/' + pathArray[1] + '/' + pathArray[2]
    }

    return (
        <Layout>
            <Sider
                collapsed={isCollapsed}
                onCollapse={(collapsedState) => setIsCollapsed(collapsedState)}
                width={260}
                style={{
                    overflow: "auto",
                    height: "100vh",
                    position: "fixed",
                    left: 0,
                    background: "white",
                    borderRight: "1px solid #f0f0f0",
                }}
            >
                {!isCollapsed && (
                    <LogoImage src={"/assets/images/logo_b.png"} />
                )}
                <Menu
                    mode="inline"
                    activeKey={location.pathname}
                    defaultOpenKeys={openKey}
                    selectedKeys={[location.pathname]}
                >
                    {privateRoute.map((parentRoute) =>
                        (
                            parentRoute.children ? (
                                <SubMenu
                                    key={parentRoute.path}
                                    icon={Object(parentRoute.sidebar).icon}
                                    title={Object(parentRoute.sidebar).label}
                                    style={{ fontSize: 14 }}
                                >
                                    {parentRoute.children.map(
                                        ({ visible = true, ...childrenRoute }) =>
                                            childrenRoute?.sub ? (<SubMenu
                                                    key={`${parentRoute.path}${childrenRoute.path}`}
                                                    title={Object(childrenRoute.sidebar).label}
                                                    style={{ fontSize: 14 }}
                                                >
                                                    {childrenRoute.sub.map(({ visible = true, ...sub }) =>
                                                            visible && (
                                                                <Menu.Item
                                                                    key={`${parentRoute.path}${childrenRoute.path}${sub.path}`}
                                                                    icon={Object(sub.sidebar).icon}
                                                                >
                                                                    <NavLink
                                                                        to={`${parentRoute.path}${childrenRoute.path}${sub.path}`}
                                                                        className="nav-text"
                                                                        style={{ fontSize: 14 }}
                                                                    >
                                                                        {Object(sub.sidebar).label}
                                                                    </NavLink>
                                                                </Menu.Item>
                                                            )
                                                    )}
                                                </SubMenu>)
                                                : (
                                                    visible && (
                                                        <Menu.Item
                                                            key={`${parentRoute.path}${childrenRoute.path}`}
                                                            icon={Object(childrenRoute.sidebar).icon}
                                                        >
                                                            <NavLink
                                                                to={`${parentRoute.path}${childrenRoute.path}`}
                                                                className="nav-text"
                                                                style={{ fontSize: 14 }}
                                                            >
                                                                {Object(childrenRoute.sidebar).label}
                                                            </NavLink>
                                                        </Menu.Item>
                                                    )
                                                )
                                    )}
                                </SubMenu>
                            )  : (
                                <Menu.Item
                                    key={parentRoute.path}
                                    icon={Object(parentRoute.sidebar).icon}
                                >
                                    <NavLink
                                        to={parentRoute.path}
                                        className="nav-text"
                                        style={{ fontSize: 14 }}
                                    >
                                        {Object(parentRoute.sidebar).label}
                                    </NavLink>
                                </Menu.Item>
                            )
                        )
                    )}
                </Menu>
            </Sider>
            <Layout
                style={{
                    marginLeft: isCollapsed ? 80 : 260,
                    transition: "all 0.2s",
                    minHeight: "100vh",
                    backgroundColor: "white",
                }}
            >
                <Header
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0px 15px",
                        backgroundColor: "white",
                        boxShadow: "0 2px 8px #f0f1f2",
                    }}
                >
                    <MenuOutlined
                        style={{ fontSize: 20 }}
                        onClick={() => setIsCollapsed((prevState) => !prevState)}
                    />
                    <Popover
                        trigger="click"
                        placement="bottomRight"
                        content={
                            <>
                                <PopoverContents>
                                    <Popconfirm
                                        title="로그아웃 하시겠습니까?"
                                        onConfirm={logout}
                                        okText="확인"
                                        cancelText="취소"
                                    >
                  <span style={{ color: "black", cursor: "pointer" }}>
                    로그아웃
                  </span>
                                    </Popconfirm>
                                </PopoverContents>
                            </>
                        }
                        visible={isVisibleProfilePopover}
                        onVisibleChange={(visibleState) =>
                            setIsVisibleProfilePopover(visibleState)
                        }
                    >
                        < UserOutlined/>
                    </Popover>
                </Header>
                <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                    {children}
                </Content>
                <Footer style={{ textAlign: "center" }}>{constant.footerText}</Footer>
            </Layout>
        </Layout>
    );
};

function getRoute(patch, gradeInfo) {
    if (patch) {
            gradeInfo?.map((data, key) => {
                if (data === "dashboard_manage" && patch.includes('dashboard')) {
                    return true;
                } else if (data === 'user_manage' && patch.includes('user')) {
                    return true;
                } else if (data === "product_manage" && patch.includes('product')) {
                    return true;
                } else if (data === "detailing_manage" && patch.includes('detailing')) {
                    return true;
                } else if (data === "order_manage" && patch.includes('order')) {
                    return true;
                } else if (data === "stats_manage" && patch.includes('stats')) {
                    return true;
                } else if (data === "survey_manage" && patch.includes('preference')) {
                    return true;
                } else if (data === "contents_manage" && patch.includes('contents')) {
                    return true;
                } else if (data === "coupon_manage" && patch.includes('coupon')) {
                    return true;
                } else if (data === "cash_manage" && patch.includes('cash')) {
                    return true;
                } else if (data === "alarm_manage" && patch.includes('alarm')) {
                    return true;
                } else if (data === "admin_manage" && patch.includes('admin')) {
                    return true;
                } else if (data === "etc_manage" && patch.includes('etc')) {
                    return true;
                } else {
                    return false;
                }
            })

    }
}

const ProfileImage = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  cursor: pointer;
`;

const PopoverContents = styled.div`
  width: 150px;
`;

const LogoImage = styled.img`
  width: 100%;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  padding: 40px;
`;

export default ServiceWrapper;
